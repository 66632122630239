import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  List,
  SettingField,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { HierarchyCountrySettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';
import { toggleHierarchyElementSettings } from '../../utils';
import { THierarchySettingsItemProps } from '../hierarchy-permissions';

 type TOneHierarchyCountrySetting = {
    [K in keyof HierarchyCountrySettingsShape]: Record<K, HierarchyCountrySettingsShape[K]>;
  }[keyof HierarchyCountrySettingsShape];


const CountrySettings = ({
  heading,
  hierarchySettings,
  onChangeHierarySettings,
}: THierarchySettingsItemProps) => {
  const { t } = useTranslation();
  const countrySettings = hierarchySettings?.countrySettings;

  if (!countrySettings) {
    return null;
  }

  const onChangeCountryHierarchySettings = (changedSettings: TOneHierarchyCountrySetting) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      countrySettings: {
        ...countrySettings,
        [changedKey]: changedSettings[changedKey as keyof TOneHierarchyCountrySetting]
      }
    };
    onChangeHierarySettings(changes);
  };

  const items = [
    {
      details: (
        <SettingField
          helpText="Select who is permitted to create a new country in the hierarchy."
          selectedOption={countrySettings?.lockNodeCreation}
          handleSelectChange={() => onChangeCountryHierarchySettings({
            lockNodeCreation: !countrySettings?.lockNodeCreation
          })}
        >
          {t(translations.settings_createCountry)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={countrySettings?.location?.isLocked}
          helpText="Select who is permitted to edit existing countries in the hierarchy."
          handleSelectChange={() => onChangeCountryHierarchySettings({
            location: toggleHierarchyElementSettings(countrySettings?.location, !countrySettings?.location?.isLocked)
          })}
        >
          {t(translations.settings_editCountry)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={countrySettings?.lockNodeDeletion}
          helpText="Select who is permitted to delete an existing country - and all the data associated with a country."
          handleSelectChange={() => onChangeCountryHierarchySettings({
            lockNodeDeletion: !countrySettings?.lockNodeDeletion
          })}
        >
          {t(translations.settings_deleteCountry)}
        </SettingField>

      )
    },
  ];

  const listProperties: any = {
    name: heading,
    items: items?.filter(item => item),
    actions: [],
  };

  return (
    <div >
      <List list={listProperties} drawer />
    </div>
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchySettings: selectHierarchySettings(entities),
});

const Component = connect(mapStateToProps)(CountrySettings);

 export { Component as CountrySettings };
