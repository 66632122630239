import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from '@oliasoft-open-source/react-ui-library';
import {
  getItems,
  itemSelected,
  listCleaned,
} from '~store/entities/hierarchy/hierarchy';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { canManageHierarchySettings } from '~src/views/settings/company-settings/hierarchy/utils';
import {
  IField,
  ISite,
  ISelectedProject,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { useListSorting } from '~common/sorting/use-list-sorting.tsx';
import { FetchingList } from '~common/lists/fetching-list.tsx';
import { isEmpty } from 'lodash';
import { HierarchySiteSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import { selectHierarchyLabelsSettings } from '~src/store/entities/company-settings/selectors';
import { updateProjectHierarchy } from '../hierarchy-utils/local-storage-util';
import { getHierarchyLevelLable } from '../hierarchy-utils/utils';
import type { TRootState } from '~src/store/store-types';

type SitesListProps = PropsFromRedux & {
  companyId: string;
  sites: ISite[];
  activeField: IField | null;
  isSiteFetching: boolean;
  addButtonDisabled: boolean;
  createItem: () => void;
  onClickDelete: (id: string, type: HierarchyLevelType) => void;
  editItem: (item: ISite, type: HierarchyLevelType) => void;
  selectedProject: ISelectedProject;
  settings: HierarchySiteSettingsShape;
  hierarchyLabelType: string;
};

const SitesList = ({
  companyId,
  sites,
  onClickDelete,
  activeField,
  getItems,
  itemSelected,
  listCleaned,
  addButtonDisabled,
  isSiteFetching,
  createItem,
  editItem,
  selectedProject,
  settings,
  hierarchyLabelType
}: SitesListProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (activeField?.fieldid) {
      const fetchData = async () => {
        await getItems(companyId, HierarchyLevelType.Site, activeField.fieldid);
        if (!isEmpty(selectedProject.siteId)) {
          itemSelected({
            id: selectedProject.siteId,
            type: HierarchyLevelType.Site,
          });
        }
      };
      fetchData();
    }
    return () => {
      listCleaned(HierarchyLevelType.Site);
    };
  }, [companyId, activeField]);
  const { items, sortAction } = useListSorting(sites);

  if (isSiteFetching) {
    return <FetchingList testId="site-list" name={getHierarchyLevelLable(HierarchyLevelType.Site, hierarchyLabelType)} />;
  }

  return (
    <List
      testId="site-list"
      list={{
        name: getHierarchyLevelLable(HierarchyLevelType.Site, hierarchyLabelType),
        actions: [
          sortAction,
          {
            label: 'Add',
            icon: 'add',
            onClick: () => createItem(),
            disabled: addButtonDisabled || isSiteFetching || (settings?.lockNodeCreation && !canManageHierarchySettings()),
          },
        ],
        items: items.map((site) => {
          const { childrenCount } = site.metadata;

          return {
            id: site.siteid,
            name: site.name,
            metadata: `${childrenCount} ${
              +childrenCount === 1
                ? t(translations.well)
                : t(translations.wells)
            }`,
            active: site.active,
            onClick: () => {
              itemSelected({
                id: site.siteid,
                type: HierarchyLevelType.Site,
              });
              updateProjectHierarchy('siteId', site.siteid, selectedProject);
            },
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => editItem(site, HierarchyLevelType.Site),
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(site.siteid, HierarchyLevelType.Site),
                disabled: settings?.lockNodeDeletion && !canManageHierarchySettings(),
              },
            ],
          };
        }),
      }}
      stickyHeader
      expanding
      scrollDetails={{
        scrollable: true,
        hideScrollbar: true,
      }}
    />
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchyLabelType: selectHierarchyLabelsSettings(entities)
})

const mapDispatchToProps = { getItems, itemSelected, listCleaned };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(SitesList));

export { Container as SitesList };
