import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Row, Column, DisabledContext, Spacer, Heading, Accordion } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { selectCompanySettingsData } from '~src/store/entities/company-settings/selectors';
import { updateHierarchySettings } from '~src/store/entities/company-settings/company-settings';
import { canManageHierarchySettings } from '~src/views/settings/company-settings/hierarchy/utils';
import { HierarchyContentSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import { hierarchyContentEntities } from './hierarchy-content-settings/hierarchy-content-entities';
import type { TRootState } from '~src/store/store-types';

const HierarchyOptionalParameters = ({
  companySettings,
  updateHierarchySettings
}: PropsFromRedux) => {
  const { t } = useTranslation();
  const { company = '' } = useParams();

  const onChangeSetting = (changedSettings: {hierarchyContentSettings: HierarchyContentSettingsShape}) => {
    updateHierarchySettings({
        ...companySettings,
        ...changedSettings
      }, company);
  };

  return (
    <Column padding={true}>
      <Accordion expanded heading={<Heading>{t(translations.settings_hierarchyContentSettings)}</Heading>} managed bordered>
        <DisabledContext.Provider value={!canManageHierarchySettings()}>
          {hierarchyContentEntities?.map((props, idx) => (
            <Row key={idx} wrap spacing={0}>
              <Column widthTablet="50%" widthMobile="100%">
                <props.component heading={props.heading} onChangeSetting={onChangeSetting}  />
                <Spacer />
              </Column>
            </Row>
          ))}
        </DisabledContext.Provider>
      </Accordion>
    </Column>
  );
};

const mapDispatchToProps = {
  updateHierarchySettings
};

const mapStateToProps = ({ entities }: TRootState) => ({
  companySettings: selectCompanySettingsData(entities),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(HierarchyOptionalParameters));

export { Container as HierarchyOptionalParameters };