import jsPDF from "jspdf";
import { format } from "date-fns";
import { IProjectsOverview } from "~src/common/interfaces/project";
import { lineInterval, padding } from "./common";

export const addHierarchyData = (doc: jsPDF, projectOverview: IProjectsOverview, startY: number = 180) => {
  const hierarchyData = [
    { label: 'Wellbore', value: projectOverview?.wellbore.name },
    { label: 'Well', value: projectOverview.well.name },
    { label: 'Site', value: projectOverview.site.name },
    { label: 'Field', value: projectOverview.field.name },
    { label: 'Country', value: projectOverview.country.name },
    {
      label: 'Start Date', value: projectOverview?.startDate ? format(
        new Date(projectOverview.startDate),
        'd.LLL-yyyy',
      ) : ''
    },
  ];

  const pdfWidth = doc.internal.pageSize.getWidth();

  doc.setFontSize(12);

  // Add bold labels and normal values
  const startX = pdfWidth / 2 - padding / 2; // X coordinate for labels
  const valueX = pdfWidth / 2 + padding / 2; // X coordinate for values
  let currentY = startY; // Starting Y coordinate

  // Loop to add text
  hierarchyData.forEach((item) => {
    // Add bold label
    doc.setFont('helvetica', 'bold');
    doc.text(`${item.label}`, startX, currentY, { align: 'right' });

    // Add normal value
    doc.setFont('helvetica', 'normal');
    doc.text(`${item.value}`, valueX, currentY, { align: 'left' });

    // Increment Y position for the next row
    currentY += lineInterval;
  });
};