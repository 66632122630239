import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  List,
  SettingField,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { HierarchyFieldSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';
import { toggleHierarchyElementSettings } from '../../utils';
import { THierarchySettingsItemProps } from '../hierarchy-permissions';

 type TOneHierarchyFieldSetting = {
    [K in keyof HierarchyFieldSettingsShape]: Record<K, HierarchyFieldSettingsShape[K]>;
  }[keyof HierarchyFieldSettingsShape];

const FieldSettings = ({
  heading,
  hierarchySettings,
  onChangeHierarySettings,
}: THierarchySettingsItemProps) => {
  const { t } = useTranslation();
  const fieldSettings = hierarchySettings?.fieldSettings;

  if (!fieldSettings) {
    return null;
  }

  const onChangeFieldHierarchySettings = (changedSettings: TOneHierarchyFieldSetting) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      fieldSettings: {
        ...fieldSettings,
        [changedKey]: changedSettings[changedKey as keyof TOneHierarchyFieldSetting]
      }
    };
    onChangeHierarySettings(changes);
  };

  const items = [
    {
      details: (
        <SettingField
          selectedOption={fieldSettings?.lockNodeCreation}
          handleSelectChange={() => onChangeFieldHierarchySettings({
            lockNodeCreation: !fieldSettings?.lockNodeCreation
          })}
          helpText="Select who is permitted to create a new field in the hierarchy."
        >
          {t(translations.settings_createField)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          helpText="Select who is permitted to edit an existing field name in the hierarchy."
          selectedOption={fieldSettings?.lockName}
          handleSelectChange={() => onChangeFieldHierarchySettings({
            lockName: !fieldSettings?.lockName
          })}
        >
          {t(translations.settings_editFieldName)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          helpText="Select who is permitted to edit an existing field location in the hierarchy."
          selectedOption={fieldSettings?.location?.isLocked}
          handleSelectChange={() => onChangeFieldHierarchySettings({
            location: toggleHierarchyElementSettings(fieldSettings?.location, !fieldSettings?.location?.isLocked)
          })}
        >
          {t(translations.settings_editFieldLocation)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={fieldSettings?.lockNodeDeletion}
          handleSelectChange={() => onChangeFieldHierarchySettings({
            lockNodeDeletion: !fieldSettings?.lockNodeDeletion
          })}
          helpText="Select who is permitted to delete an existing field - and all the data associated with a field."
        >
          {t(translations.settings_deleteField)}
        </SettingField>
      )
    },
  ];

  const listProperties: any = {
    name: heading,
    items: items?.filter(item => item),
    actions: [],
  };

  return (
    <div>
      <List list={listProperties} drawer />
    </div>
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchySettings: selectHierarchySettings(entities),
});

const Component = connect(mapStateToProps)(FieldSettings);

 export { Component as FieldSettings };
