import axios from 'axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan, getAccessToken } from '~store/middleware/api/api';
import { HierarchyLevelType, IdFieldMap } from '~src/enums/hierarchy';
import {
  TItemIdField,
  THierarchyLists,
  THierarchySliceState,
  IEstimate,
} from '~src/common/interfaces/hierarchy.interfaces.ts';
import ClientConfig from '~src/config/config.ts';

const deselectListItems = (list: any) =>
  list.map((item: any) => {
    item.active = false;
    return item;
  });

const deselectHierarchyItems = (
  lists: THierarchyLists,
  type: HierarchyLevelType,
) => {
  const {
    field: { data: field },
    site: { data: site },
    well: { data: well },
    wellbore: { data: wellbore },
    design: { data: design },
    estimate: { data: estimate },
  } = lists;
  if (type === HierarchyLevelType.Country) {
    deselectListItems(field);
    deselectListItems(site);
    deselectListItems(well);
    deselectListItems(wellbore);
    deselectListItems(design);
    deselectListItems(estimate);
  }

  if (type === HierarchyLevelType.Field) {
    deselectListItems(site);
    deselectListItems(well);
    deselectListItems(wellbore);
    deselectListItems(design);
    deselectListItems(estimate);
  }

  if (type === HierarchyLevelType.Site) {
    deselectListItems(well);
    deselectListItems(wellbore);
    deselectListItems(design);
    deselectListItems(estimate);
  }

  if (type === HierarchyLevelType.Well) {
    deselectListItems(wellbore);
    deselectListItems(design);
    deselectListItems(estimate);
  }

  if (type === HierarchyLevelType.Wellbore) {
    deselectListItems(design);
    deselectListItems(estimate);
  }

  if (type === HierarchyLevelType.Design) {
    deselectListItems(estimate);
  }
};

const updateMetaCount = (
  lists: THierarchyLists,
  type: HierarchyLevelType,
  isAdd = true,
) => {
  const typesOrderMap = [
    HierarchyLevelType.Country,
    HierarchyLevelType.Field,
    HierarchyLevelType.Site,
    HierarchyLevelType.Well,
    HierarchyLevelType.Wellbore,
    HierarchyLevelType.Design,
    HierarchyLevelType.Estimate,
  ];
  const typeToUpdate =
    typesOrderMap[typesOrderMap.findIndex((item) => item === type) - 1];

  if (typeToUpdate) {
    const itemIndex = lists[typeToUpdate].data.findIndex((item) => item.active);
    if (itemIndex !== -1) {
      lists[typeToUpdate].data[itemIndex].metadata.childrenCount += isAdd
        ? 1
        : -1;
    }
  }
};

const initialState: THierarchySliceState = {
  isAdding: false,
  isFetching: false,
  designModalVisible: false,
  estimateModalVisible: false,
  deleteModalVisible: false,
  selectedId: null,
  selectedType: null,
  countriesList: [],
  list: {
    [HierarchyLevelType.Country]: { isFetching: false, data: [] },
    [HierarchyLevelType.Field]: { isFetching: false, data: [] },
    [HierarchyLevelType.Site]: { isFetching: false, data: [] },
    [HierarchyLevelType.Well]: { isFetching: false, data: [] },
    [HierarchyLevelType.Wellbore]: { isFetching: false, data: [] },
    [HierarchyLevelType.Design]: { isFetching: false, data: [] },
    [HierarchyLevelType.Estimate]: { isFetching: false, data: [] },
  },
};

const hierarchy = createSlice({
  name: 'hierarchy',
  initialState,
  reducers: {
    itemsRequested: (
      hierarchy,
      action: PayloadAction<{ type: HierarchyLevelType }>,
    ) => {
      hierarchy.list[action.payload.type].isFetching = true;
    },
    itemsFetched: (
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        items: any[];
      }>,
    ) => {
      const { items, type } = action.payload;
      // Preselect the item if it's the only one on the list
      if (items.length === 1 && type !== HierarchyLevelType.Estimate) {
        items[0].active = true;
      }

      hierarchy.list[type].isFetching = false;
      hierarchy.list[type].data = items;
    },
    itemSelected(
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        id: string;
      }>,
    ) {
      const { list } = hierarchy;
      const { type, id } = action.payload;
      const items = list[type].data;

      hierarchy.list[type].data = items.map((item: any) => {
        item.active = item[IdFieldMap.get(type) as TItemIdField] === id;
        return item;
      });

      deselectHierarchyItems(list, type);
    },
    itemCreateRequested: (hierarchy) => {
      hierarchy.isAdding = true;
    },
    itemCreated: (
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        item: any;
      }>,
    ) => {
      const { list } = hierarchy;
      const { type, item } = action.payload;
      const itemData = {
        ...item,
        metadata: { childrenCount: 0 },
        active: true,
      };

      hierarchy.list[type].data.push(itemData);
      updateMetaCount(list, type);

      hierarchy.isAdding = false;
      hierarchy.designModalVisible = false;
    },
    countriesListRequested: (hierarchy) => {
      hierarchy.isFetching = true;
    },
    countriesListFetched: (hierarchy, action) => {
      hierarchy.isFetching = false;
      hierarchy.countriesList = action.payload.countriesList;
    },
    itemModalVisibleUpdated: (
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        value: boolean;
      }>,
    ) => {
      const { type, value } = action.payload;
      if (type === HierarchyLevelType.Design) {
        hierarchy.designModalVisible = value;
      }
      if (type === HierarchyLevelType.Estimate) {
        hierarchy.estimateModalVisible = value;
      }
    },
    deleteModalVisibleUpdated: (hierarchy, action: PayloadAction<boolean>) => {
      hierarchy.deleteModalVisible = action.payload;
    },
    itemDeleteRequested: (
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        id: string;
      }>,
    ) => {
      const { id, type } = action.payload;
      hierarchy.selectedId = id;
      hierarchy.selectedType = type;
      hierarchy.deleteModalVisible = true;
    },
    itemDeleted: (
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        item: any;
      }>,
    ) => {
      const { item, type } = action.payload;
      const {
        list: {
          [type]: { data: itemsList },
        },
      } = hierarchy;

      const itemIndex = itemsList.findIndex(
        (listItem: any) =>
          listItem[IdFieldMap.get(type) as TItemIdField] === item.id,
      );

      if (itemIndex !== -1) {
        itemsList.splice(itemIndex, 1);
        hierarchy.list[type].data = itemsList;

        updateMetaCount(hierarchy.list, type, false);
      }

      hierarchy.deleteModalVisible = false;
    },
    listCleaned: (hierarchy, action: PayloadAction<HierarchyLevelType>) => {
      hierarchy.list[action.payload].data = [];
    },
    itemUpdateRequested: (hierarchy) => {
      hierarchy.isAdding = true;
    },
    itemUpdated: (
      hierarchy,
      action: PayloadAction<{
        type: HierarchyLevelType;
        item: any;
      }>,
    ) => {
      const { type, item } = action.payload;
      const {
        list: {
          [type]: { data: itemsList },
        },
      } = hierarchy;
      const idField = IdFieldMap.get(type) as TItemIdField;
      const itemIndex = itemsList.findIndex(
        (listItem: any) => listItem[idField] === item[idField],
      );

      if (itemIndex !== -1) {
        hierarchy.list[type].data[itemIndex] = item;
      }

      hierarchy.isAdding = false;
      hierarchy.designModalVisible = false;
      hierarchy.estimateModalVisible = false;
    },
  },
});

export const {
  itemsRequested,
  itemsFetched,
  itemSelected,
  itemCreateRequested,
  itemCreated,
  countriesListRequested,
  countriesListFetched,
  itemModalVisibleUpdated,
  deleteModalVisibleUpdated,
  itemDeleteRequested,
  itemDeleted,
  listCleaned,
  itemUpdateRequested,
  itemUpdated,
} = hierarchy.actions;
export default hierarchy.reducer;

/**
 * Fetches the list of countries from the specified API endpoint.
 */
export const getCountriesList = () =>
  apiCallBegan({
    url: `/api/hierarchy/countries-list`,
    method: 'GET',
    onStart: countriesListRequested.type,
    onSuccess: (response) => ({
      type: countriesListFetched.type,
      payload: response,
    }),
  });

/**
 * Fetches hierarchy items based on the provided parameters.
 */
export const getItems = (
  companyId: string,
  type: HierarchyLevelType,
  parentId: string | null = null,
  withMetaData = true,
) =>
  apiCallBegan({
    url: `/api/hierarchy/${companyId}?type=${type}&withMetaData=${withMetaData}${
      parentId ? `&parentId=${parentId}` : ''
    }`,
    method: 'GET',
    onStart: () => ({ type: itemsRequested.type, payload: { type } }),
    onSuccess: (response) => ({
      type: itemsFetched.type,
      payload: { ...response, type },
    }),
  });

/**
 * Creates an item in the hierarchy for a given company.
 */
export const createHierarchyItem = (
  companyId: string,
  type: HierarchyLevelType,
  data: any,
) =>
  apiCallBegan({
    url: `/api/hierarchy/${companyId}?type=${type}`,
    method: 'POST',
    data,
    onStart: itemCreateRequested.type,
    onSuccess: (response) => ({
      type: itemCreated.type,
      payload: {
        item: { ...response.item },
        type,
      },
    }),
  });

/**
 * Deletes an item from the hierarchy.
 */
export const deleteHierarchyItem = (
  companyId: string,
  type: HierarchyLevelType,
  id: string,
) =>
  apiCallBegan({
    url: `/api/hierarchy/${companyId}/${id}?type=${type}`,
    method: 'DELETE',
    onSuccess: (response) => ({
      type: itemDeleted.type,
      payload: {
        ...response,
        type,
      },
    }),
  });

/**
 * Updates an item in the hierarchy.
 */
export const updateHierarchyItem = (
  companyId: string,
  type: HierarchyLevelType,
  id: string,
  data: any,
) =>
  apiCallBegan({
    url: `/api/hierarchy/${companyId}/${id}?type=${type}`,
    method: 'PUT',
    data,
    onStart: itemUpdateRequested.type,
    onSuccess: () => ({
      type: itemUpdated.type,
      payload: {
        item: data,
        type,
      },
    }),
  });

/**
 * Fetches the children of a hierarchy item for a given company.
 * This function was created for HierarchyModals from hierarchy-ui package to get affected child items.
 *
 * @param {string} companyId - The ID of the company.
 * @param {HierarchyLevelType} type - The type of the hierarchy level.
 * @param {string} id - The ID of the hierarchy item.
 */
export const getHierarchyItemChildren = async (
  companyId: string,
  type: HierarchyLevelType,
  id: string,
) => {
  const response = await axios.request({
    method: 'GET',
    url: `/api/hierarchy/${companyId}/children/${id}?type=${type}`,
    baseURL: ClientConfig.config.baseApiUrl,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response?.data;
};

/**
 * Function to create a new project with hierarchy using provided data.
 *
 * @param {object} data
 */
export const createHierarchyWithProject = (data: any) =>
  apiCallBegan({
    url: `/api/projects`,
    method: 'POST',
    data,
    onStart: itemCreateRequested.type,
    onSuccess: (response) => ({
      type: itemCreated.type,
      payload: {
        item: { ...response.estimate },
        type: HierarchyLevelType.Estimate,
      },
    }),
  });

/**
 * Copy an existing project.
 *
 * @param {IEstimate} data - The estimate data to duplicate.
 */
export const duplicateProject = (data: IEstimate) =>
  apiCallBegan({
    url: `/api/projects/duplicate`,
    method: 'POST',
    data,
    onStart: itemCreateRequested.type,
    onSuccess: (response) => ({
      type: itemCreated.type,
      payload: {
        item: { ...response.estimate },
        type: HierarchyLevelType.Estimate,
      },
    }),
  });

