import { isFunction, isString } from 'lodash';
import axios, {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
  isAxiosError,
} from 'axios';
import {
  Dispatch,
  Middleware,
  createAction,
  Action,
  PayloadAction,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { User } from '@oliasoft/pkce';
import ClientConfig from '~src/config/config';

axios.interceptors.request.use((config) => {
  const company = JSON.parse(localStorage.getItem('activeCompany') as string);

  return {
    ...config,
    params: {
      ...config.params,
      companyId: company?.id,
      companyName: company?.name,
    },
  };
});

type GeneralCallback = (payload: any) => any;

type PayloadActionCreatorCallback = (payload: any) => PayloadAction<any>;

type APICallbackActionType =
  | string
  | Action
  | PayloadActionCreatorCallback
  | GeneralCallback;

export type APIActionPayload = AxiosRequestConfig & {
  onStart?: APICallbackActionType;
  onSuccess?: APICallbackActionType;
  onError?: APICallbackActionType;
};

export const apiCallBegan = createAction<APIActionPayload>('api/CallBegan');

export const apiCallSuccess = createAction<AxiosResponse>('api/CallSuccess');

export const apiCallFailed = createAction<AxiosError | unknown>(
  'api/CallFailed',
);

export const dispatchAPICallback = (
  dispatch: Dispatch,
  action?: APICallbackActionType,
  payload?: unknown,
) => {
  if (action) {
    if (isFunction(action)) {
      // invoke the callback function
      const result = action(payload);
      // if the function was an action creator, the result will have an action type, so dispatch it
      if (result?.type) {
        dispatch(result);
      }
    } else if (isString(action)) {
      dispatch({ type: action, payload });
    }
  }
};

export const getAccessToken = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${ClientConfig.config.idpServerUrl}:${ClientConfig.config.applicationId}`,
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage)?.access_token || '';
};

const api: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) {
      return next(action);
    }

    const { baseURL, headers, onStart, onSuccess, onError }: APIActionPayload =
      action.payload;

    if (onStart) {
      dispatchAPICallback(dispatch, onStart);
    }
    next(action);

    try {
      const response = await axios.request({
        ...action.payload,
        baseURL: baseURL ?? ClientConfig.config.baseApiUrl,
        headers: {
          ...headers,
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      dispatch(apiCallSuccess(response.data));
      dispatchAPICallback(dispatch, onSuccess, response.data);
    } catch (error) {
      // Axios errors are not serializable so don't send full error as action payload
      const errorPayload = isAxiosError(error)
        ? {
            message: error?.message,
            code: error?.code,
            status: error?.response?.status,
            errorMessages: error?.response?.data?.errorMessages || error?.response?.data?.message,
          }
        : { message: 'An API error occurred' };
      dispatch(apiCallFailed(errorPayload));
      dispatchAPICallback(dispatch, onError, errorPayload);
    }
  };

export default api;
