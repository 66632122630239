import { createSelector } from '@reduxjs/toolkit';

// /**
//  * Retrieves the company settings from the given entities.
//  *
//  * @param {Array} entities - The entities to retrieve the company settings from.
//  * @returns {Array} - The company settings.
//  */ TODO: Fix proper types
const selectCompanySettings = (entities) => entities?.companySettings;

/**
 * Selects the primary currency from the company settings.
 *
 * @param {Function} selectCompanySettings - The selector function to retrieve the company settings.
 * @returns {string|null} The primary currency value or null if not found.
 */
export const selectPrimaryCurrency = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings.settings?.currencies?.primaryCurrency,
);

/**
 * Retrieves the available currencies from the company settings.
 *
 * @param {Object} companySettings - The company settings object.
 * @returns {Array} - An array containing the available currencies.
 */
export const selectCurrencies = createSelector(
  [selectCompanySettings],
  (companySettings) => {
    const currenciesSettings = companySettings.settings?.currencies;
    const currencies = [];

    if (currenciesSettings) {
      currencies.push(currenciesSettings.primaryCurrency);
      const additionalCurrencies = currenciesSettings.additionalCurrencies.map(
        (item) => item.currency,
      );
      currencies.push(...additionalCurrencies);
    }

    return currencies;
  },
);

export const selectCompanyKpiList = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings.settings.kpi,
);

export const selectCompanyTemplates = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings.settings.templates,
);

export const selectCompanyTimeTrackerSettings = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings.settings.timeTracker,
);

const percentileLabelMap = {
  mean: 'Mean',
  10: 'P10',
  50: 'P50',
  90: 'P90',
};

export const selectTimeTrackerLabelCofig = createSelector(
  [selectCompanyTimeTrackerSettings],
  (timeTrackerSettings) => {
    return timeTrackerSettings?.reduce((labels, item) => {
      labels[item.timeVariable] = `${item.label} (${
        percentileLabelMap[item.percentile]
      })`;
      return labels;
    }, {});
  },
);

export const selectCompanySectionTypes = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings.sectionTypes,
);

export const selectActiveTemplateOperations = createSelector(
  [selectCompanyTemplates],
  (templates) => templates.find((template) => template.active).activityModel,
);

export const selectGroupedOperations = createSelector(
  [selectCompanySectionTypes, selectActiveTemplateOperations],
  (sections, operationList) =>
    sections.reduce(
      (acc, { linkedSectionId, sectionName, sectionTypeName }) => {
        const sectionOperation = operationList.filter(
          ({ linkedSectionType }) => linkedSectionType === linkedSectionId,
        );
        if (sectionOperation.length > 0) {
          acc.push({
            linkedSectionId,
            sectionName,
            operations: sectionOperation,
            sectionTypeName,
          });
        }
        return acc;
      },
      [],
    ),
);

export const selectCompanySettingsData = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings?.settings,
);

export const selectHierarchySettings = createSelector(
  [selectCompanySettingsData],
  (companySettingsData) => companySettingsData?.hierarchySettings,
);

export const selectHierarchyContentSettings = createSelector(
  [selectCompanySettingsData],
  (companySettingsData) => companySettingsData?.hierarchyContentSettings,
);

export const selectHierarchyModalSettings = createSelector(
  [selectHierarchySettings, selectHierarchyContentSettings],
  (hierarchySettings, hierarchyContentSettings) => ({
    ...hierarchySettings,
    hierarchyContentSettings,
    configuration: { well_configuration: '' },
  }),
);

export const selectCompanySettingsFetching = createSelector(
  [selectCompanySettings],
  (companySettings) => companySettings.isFetching,
);

export const selectHierarchyLabelsSettings = createSelector(
  [selectHierarchySettings],
  (hierarchySettings) => hierarchySettings?.labelsSettings.type,
);
