import { ReactNode, useEffect } from 'react';
import { useAuth } from '@oliasoft/pkce';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import * as Sentry from '@sentry/react';

type TSentryConfigurationProps = {
  children: ReactNode;
};

const RawSentryConfiguration = ({
  children,
}: TSentryConfigurationProps) => {
  const auth = useAuth();

  const userEmail = auth?.user?.profile?.email;

  useEffect(() => {
    Sentry.setUser({ email: userEmail })
  }, [userEmail]);

  return children;
};

export const SentryConfiguration = withErrorBoundary(RawSentryConfiguration);
