import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  List,
  SettingField,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { HierarchySiteSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';
import { toggleHierarchyElementSettings } from '../../utils';
import { THierarchySettingsItemProps } from '../hierarchy-permissions';

 type TOneHierarchySiteSetting = {
    [K in keyof HierarchySiteSettingsShape]: Record<K, HierarchySiteSettingsShape[K]>;
  }[keyof HierarchySiteSettingsShape];

const SiteSettings = ({
  heading,
  hierarchySettings,
  onChangeHierarySettings,
}: THierarchySettingsItemProps) => {
  const { t } = useTranslation();
  const siteSettings = hierarchySettings?.siteSettings;

  if (!siteSettings) {
    return null;
  }

  const onChangeSiteHierarchySettings = (changedSettings: TOneHierarchySiteSetting) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      siteSettings: {
        ...siteSettings,
        [changedKey]: changedSettings[changedKey as keyof TOneHierarchySiteSetting]
      }
    };
    onChangeHierarySettings(changes);
  };

  const items = [
    {
      details: (
        <SettingField
          selectedOption={siteSettings?.lockNodeCreation}
          handleSelectChange={() => onChangeSiteHierarchySettings({
            lockNodeCreation: !siteSettings?.lockNodeCreation
          })}
          helpText="Select who is permitted to create a new site in the hierarchy."
        >
          {t(translations.settings_createSite)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={siteSettings?.lockName}
          handleSelectChange={() => onChangeSiteHierarchySettings({
            lockName: !siteSettings?.lockName
          })}
          helpText="Select who is permitted to edit an existing site name in the hierarchy."
        >
          {t(translations.settings_editSiteField)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={siteSettings?.location?.isLocked}
          handleSelectChange={() => onChangeSiteHierarchySettings({
            location: toggleHierarchyElementSettings(siteSettings?.location, !siteSettings?.location?.isLocked)
          })}
          helpText="Select who is permitted to edit an existing site location in the hierarchy."
        >
          {t(translations.settings_editSiteLocation)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={siteSettings?.uncertainty?.isLocked}
          handleSelectChange={() => onChangeSiteHierarchySettings({
            uncertainty: toggleHierarchyElementSettings(siteSettings?.uncertainty, !siteSettings?.uncertainty?.isLocked)
          })}
          helpText="Select who is permitted to edit the positional uncertainty of an existing site in the hierarchy."
        >
          {t(translations.uncertainty)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={siteSettings?.template?.isLocked}
          handleSelectChange={() => onChangeSiteHierarchySettings({
            template: toggleHierarchyElementSettings(siteSettings?.template, !siteSettings?.template?.isLocked)
          })}
          helpText="Select who is permitted to edit the template definition in an existing site in the hierarchy."
        >
          {t(translations.template)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={siteSettings?.lockNodeDeletion}
          handleSelectChange={() => onChangeSiteHierarchySettings({
            lockNodeDeletion: !siteSettings?.lockNodeDeletion
          })}
          helpText="Select who is permitted to delete an existing site - and all the data associated with a site."
        >
          {t(translations.settings_deleteSite)}
        </SettingField>
      )
    },
  ];

  const listProperties: any = {
    name: heading,
    items: items?.filter(item => item),
    actions: [],
  };

  return (
    <div >
      <List list={listProperties} drawer />
    </div>
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchySettings: selectHierarchySettings(entities),
});

const Component = connect(mapStateToProps)(SiteSettings);

export { Component as SiteSettings };