import { Grid, Heading, Spacer, Text } from '@oliasoft-open-source/react-ui-library';

const SettingHeading = ({heading, hasPermission = true}: {heading?: string, hasPermission?: boolean}) => {
  return (
    <Grid>
      <Heading top>{heading}</Heading>
      {hasPermission && (
        <Text muted>
          Company-level settings become the default for all users.
          Select who can override this default in projects. If normal users should be able to modify this then choose <strong>&ldquo;All Users&rdquo;</strong> .
          If this is a company policy and you want to ensure that all users will use this option then select <strong>&ldquo;Admins Only&rdquo;</strong>
        </Text>
      )}
      <Spacer />
    </Grid>
  );
};

export default SettingHeading;