import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Flex, List, SettingField, Toggle } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectHierarchyContentSettings } from '~src/store/entities/company-settings/selectors';
import { canManageHierarchySettings } from '~src/views/settings/company-settings/hierarchy/utils';
import { HierarchyContentSettingsShape, IInputField } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';

export interface IInputs {
  showLegalName: IInputField;
  showUwi: IInputField;
  showApiNo: IInputField;
}

export type IInputKeys = keyof IInputs

type TWellContentSettings = {
  heading: string, 
  hierarchyContentSettings: HierarchyContentSettingsShape, 
  onChangeSetting: (arg: {hierarchyContentSettings: HierarchyContentSettingsShape}) => void, 
  };

const WellContentSettings = ({ heading, hierarchyContentSettings, onChangeSetting }: TWellContentSettings) => {
  const { t } = useTranslation();

  const wellContentSettings = hierarchyContentSettings?.wellContentSettings;

  if (!wellContentSettings) {
    return null;
  }

  const inputs: IInputs = {
    showLegalName: {
      value: wellContentSettings.showLegalName,
      label: t(translations.settings_enableLegalNameInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n The legal name is a free-text field.'
    },
    showUwi: {
      value: wellContentSettings.showUwi,
      label: t(translations.settings_enableUwiInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n UWI is a free-text field.'
    },
    showApiNo: {
      value: wellContentSettings.showApiNo,
      label: t(translations.settings_enableApiNoInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n API number is only relevant for wells in the United States.'
    },
  };

  const onChangeWellHierarchyContentSettings = (changedSettings: { [x: string]: boolean }) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      hierarchyContentSettings: {
        ...hierarchyContentSettings,
        wellContentSettings: {
          ...hierarchyContentSettings.wellContentSettings,
          [changedKey]: changedSettings[changedKey]
        }
      },
    };
    onChangeSetting(changes);
  };

  const items = Object.keys(inputs).map((el) => {
    return {
      details: (
        <SettingField noPermission helpText={inputs[el as IInputKeys].helpText}>
          <Flex gap={10} alignItems="center">
            {inputs[el as IInputKeys].label}
            <Toggle
              small
              disabled={!canManageHierarchySettings()}
              name={el}
              checked={hierarchyContentSettings?.wellContentSettings?.[el as IInputKeys]}
              onChange={({ target: { checked } }) => {
                onChangeWellHierarchyContentSettings({
                  [el]: checked,
                });
              }}
            />
          </Flex>
        </SettingField>
      ),
    };
  });

  const listProperties: any = {
    name: heading,
    items: items?.filter((item) => item),
  };

  return (
    <List
      list={listProperties}
      drawer
    />
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchyContentSettings: selectHierarchyContentSettings(entities),
});

export default connect(mapStateToProps)(WellContentSettings);
