import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Flex, List, SettingField, Toggle } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectHierarchyContentSettings } from '~src/store/entities/company-settings/selectors';
import { canManageHierarchySettings } from '~src/views/settings/company-settings/hierarchy/utils';
import { HierarchyContentSettingsShape, IInputField } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';

export interface IInputs {
  showLegalName: IInputField;
  showUwi: IInputField;
  showApiNo: IInputField;
  showApiSuffixNo: IInputField;
  showWellboreNo: IInputField;
}

export type IInputKeys = keyof IInputs

type TWellboreContentSettings = {
  heading: string, 
  hierarchyContentSettings: HierarchyContentSettingsShape, 
  onChangeSetting: (arg: {hierarchyContentSettings: HierarchyContentSettingsShape}) => void, 
  };

export const WellboreContentSettings = ({ heading, hierarchyContentSettings, onChangeSetting }: TWellboreContentSettings) => {
  const { t } = useTranslation();

  const wellboreContentSettings = hierarchyContentSettings?.wellboreContentSettings;

  if (!wellboreContentSettings) {
    return null;
  }

  const inputs: IInputs = {
    showLegalName: {
      value: wellboreContentSettings.showLegalName,
      label: t(translations.settings_enableLegalNameInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n The legal name is a free-text field.'
    },
    showUwi: {
      value: wellboreContentSettings.showUwi,
      label: t(translations.settings_enableUwiInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n UWI is a free-text field.'
    },
    showApiNo: {
      value: wellboreContentSettings.showApiNo,
      label: t(translations.settings_enableApiNoInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n API number is only relevant for wells in the United States.'
    },
    showApiSuffixNo: {
      value: wellboreContentSettings.showApiSuffixNo,
      label: t(translations.settings_enableApiSuffixNoInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n API suffix number is only relevant for wells in the United States.'
    },
    showWellboreNo: {
      value: wellboreContentSettings.showWellboreNo,
      label: t(translations.settings_enableWellboreNoInput),
      helpText: 'This is an optional parameter you can choose to enable under “Well” in the hierarcy.\n Wellbore number can be used for alternative naming of wellbore.'
    },

  };

  const onChangeWellHierarchyContentSettings = (changedSettings: { [x: string]: boolean }) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      hierarchyContentSettings: {
        ...hierarchyContentSettings,
        wellboreContentSettings: {
          ...hierarchyContentSettings.wellboreContentSettings,
          [changedKey]: changedSettings[changedKey]
        },
      },
    };
    onChangeSetting(changes);
  };

  const items = Object.keys(inputs).map((el) => {
    return {
      details: (
        <SettingField noPermission helpText={inputs[el as IInputKeys].helpText}>
          <Flex gap={10} alignItems="center">
            {inputs[el as IInputKeys].label}
            <Toggle
              small
              name={el}
              disabled={!canManageHierarchySettings()}
              checked={hierarchyContentSettings?.wellboreContentSettings?.[el as IInputKeys]}
              onChange={({ target: { checked } }) => {
                onChangeWellHierarchyContentSettings({
                  [el]: checked,
                });
              }}
            />
          </Flex>
        </SettingField>
      ),
    };
  });

  const listProperties: any = {
    name: heading,
    items: items?.filter((item) => item),
    actions: [],
  };

  return (
    <div >
      <List list={listProperties} drawer />
    </div>
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchyContentSettings: selectHierarchyContentSettings(entities),
});

export default connect(mapStateToProps)(WellboreContentSettings);
