export interface IInputField {
  value: boolean;
  label: string;
  helpText: string;
}

interface HierarchyTemplateShape {
  isLocked: boolean;
}

interface HierarchyFieldLocationShape {
  isLocked: boolean;
}

interface HierarchyTemplateShape {
  isLocked: boolean;
}

interface HierarchyUncertaintyShape {
  isLocked: boolean;
}

interface HierarchyIdentifierInputsShape {
  isLocked: boolean;
}

interface HierarchyCountryLocationShape {
  isLocked: boolean;
}

export interface HierarchySiteLocationShape {
  isLocked: boolean;
  lockCoordinates: boolean;
  lockNorthReference: boolean;
  lockGridScale: boolean;
}

interface HierarchyWellLocationShape {
  isLocked: boolean;
  lockCoordinates: boolean;
  lockType: boolean;
  lockUsingSlot: boolean;
  lockUsedSlot: boolean;
}

export interface HierarchyCountrySettingsShape {
  lockNodeDeletion: boolean;
  lockNodeCreation: boolean;
  location: HierarchyCountryLocationShape;
}

export interface HierarchyFieldSettingsShape {
  lockNodeDeletion: boolean;
  lockNodeCreation: boolean;
  lockName: boolean;
  location: HierarchyFieldLocationShape;
}

export interface HierarchySiteSettingsShape {
  lockNodeDeletion: boolean;
  lockNodeCreation: boolean;
  lockName: boolean;
  location: HierarchySiteLocationShape;
  uncertainty: HierarchyUncertaintyShape;
  template: HierarchyTemplateShape;
}

export interface HierarchyWellSettingsShape {
  lockNodeDeletion: boolean;
  lockNodeCreation: boolean;
  lockName: boolean;
  location: HierarchyWellLocationShape;
  uncertainty: HierarchyUncertaintyShape;
  identifierInputs: HierarchyIdentifierInputsShape;
}

export interface HierarchyWellboreSettingsShape {
  lockNodeDeletion: boolean;
  lockNodeCreation: boolean;
  lockName: boolean;
  lockSidetrack: boolean;
  identifierInputs: HierarchyIdentifierInputsShape;
}

export enum WellConfiguration {
  ONSHORE = 'onshore',
  OFFSHORE = 'offshore',
}

interface HierarchyLabelsShape {
  type: WellConfiguration;
}

export interface HierarchySettingsShape {
  countrySettings: HierarchyCountrySettingsShape;
  fieldSettings: HierarchyFieldSettingsShape;
  siteSettings: HierarchySiteSettingsShape;
  wellSettings: HierarchyWellSettingsShape;
  wellboreSettings: HierarchyWellboreSettingsShape;
  labelsSettings: HierarchyLabelsShape;
}

interface HierarchyContentWellSettingsShape {
  showLegalName: boolean;
  showApiNo: boolean;
  showUwi: boolean;
}

interface HierarchyContentWellboreSettingsShape extends HierarchyContentWellSettingsShape {
  showWellboreNo: boolean;
  showApiSuffixNo: boolean;
}

export interface HierarchyContentSettingsShape {
  wellContentSettings: HierarchyContentWellSettingsShape;
  wellboreContentSettings: HierarchyContentWellboreSettingsShape;
}