import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  List,
  SettingField,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { canManageHierarchySettings , toggleHierarchyElementSettings } from '~src/views/settings/company-settings/hierarchy/utils';
import { selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { HierarchyWellSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';
import { THierarchySettingsItemProps } from '../hierarchy-permissions';

 type TOneHierarchyWellSetting = {
    [K in keyof HierarchyWellSettingsShape]: Record<K, HierarchyWellSettingsShape[K]>;
  }[keyof HierarchyWellSettingsShape];

const WellSettings = ({
  heading,
  hierarchySettings,
  onChangeHierarySettings,
}: THierarchySettingsItemProps) => {
  const { t } = useTranslation();
  const wellSettings = hierarchySettings?.wellSettings;

  if (!wellSettings) {
    return null;
  }

  const onChangeWellHierarchySettings = (changedSettings: TOneHierarchyWellSetting) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      wellSettings: {
        ...wellSettings,
        [changedKey]: changedSettings[changedKey as keyof TOneHierarchyWellSetting]
      }
    };
    onChangeHierarySettings(changes);
  };

  const items = [
    {
      details: (
        <SettingField
          selectedOption={wellSettings?.lockNodeCreation}
          handleSelectChange={() => onChangeWellHierarchySettings({
            lockNodeCreation: !wellSettings?.lockNodeCreation
          })}
          helpText="Select who is permitted to create a new well in the hierarchy."
        >
          {t(translations.settings_createWell)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellSettings?.lockName}
          handleSelectChange={() => onChangeWellHierarchySettings({
            lockName: !wellSettings?.lockName
          })}
          helpText="Select who is permitted to edit an existing well name in the hierarchy."
        >
          {t(translations.settings_editWellName)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellSettings?.location?.isLocked}
          handleSelectChange={() => onChangeWellHierarchySettings({
            location: toggleHierarchyElementSettings(wellSettings?.location, !wellSettings?.location?.isLocked),
          })}
          helpText="Select who is permitted to edit an existing well location in the hierarchy.\n This includes defining the positional offset type, map  and geographical position and slot definition."
        >
          {t(translations.settings_editWellLocation)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellSettings?.uncertainty?.isLocked}
          handleSelectChange={() => onChangeWellHierarchySettings({
            uncertainty: toggleHierarchyElementSettings(wellSettings?.uncertainty, !wellSettings?.uncertainty?.isLocked),
          })}
          helpText="Select who is permitted to edit the positional uncertainty of an existing well in the hierarchy."
        >
          {t(translations.settings_editWellUncertainty)}
        </SettingField>

      )
    },
    {
      details: (
        <SettingField
          disabled={!canManageHierarchySettings()}
          selectedOption={wellSettings?.identifierInputs?.isLocked}
          handleSelectChange={() => onChangeWellHierarchySettings({
            identifierInputs: toggleHierarchyElementSettings(wellSettings?.identifierInputs, !wellSettings?.identifierInputs?.isLocked)
          })}
          helpText="Select who is permitted to edit the optional well identifiers of an existing well in the hierarchy.\n This includes API numbers, NPD numbers, UWI numbers etc.\n These identifiers can be defined both on the well level and wellbore level."
        >
          {t(translations.settings_editWellIdentifiers)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellSettings?.lockNodeDeletion}
          handleSelectChange={() => onChangeWellHierarchySettings({
            lockNodeDeletion: !wellSettings?.lockNodeDeletion
          })}
          helpText="Select who is permitted to delete an existing well - and all the data associated with a well."
        >
          {t(translations.settings_deleteWell)}
        </SettingField>
      )
    },
  ];

  const listProperties: any = {
    name: heading,
    items: items?.filter(item => item),
    actions: [],
  };

  return (
    <List list={listProperties} drawer />
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchySettings: selectHierarchySettings(entities),
});

const Component = connect(mapStateToProps)(WellSettings);

export { Component as WellSettings}
