import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { List, SettingField } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { HierarchyWellboreSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import type { TRootState } from '~src/store/store-types';
import { THierarchySettingsItemProps } from '../hierarchy-permissions';

type TOneHierarchyWellboreSetting = {
  [K in keyof HierarchyWellboreSettingsShape]: Record<K, HierarchyWellboreSettingsShape[K]>;
}[keyof HierarchyWellboreSettingsShape];

const WellboreSettings = ({
  heading,
  hierarchySettings,
  onChangeHierarySettings,
}: THierarchySettingsItemProps) => {
  const { t } = useTranslation();
  const wellboreSettings = hierarchySettings?.wellboreSettings;

  if (!wellboreSettings) {
    return null;
  }

  const onChangeWellboreHierarchySettings = (changedSettings: TOneHierarchyWellboreSetting) => {
    const changedKey = Object.keys(changedSettings)?.[0];
    const changes = {
      wellboreSettings: {
        ...wellboreSettings,
        [changedKey]: changedSettings[changedKey as keyof TOneHierarchyWellboreSetting]
      }
    };
    onChangeHierarySettings(changes);
  };

  const items = [
    {
      details: (
        <SettingField
          selectedOption={wellboreSettings?.lockNodeCreation}
          handleSelectChange={() => onChangeWellboreHierarchySettings({
            lockNodeCreation: !wellboreSettings?.lockNodeCreation
          })}
          helpText="Select who is permitted to create a new wellbore in the hierarchy."
        >
          {t(translations.settings_createWellbore)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellboreSettings?.lockName}
          handleSelectChange={() => onChangeWellboreHierarchySettings({
            lockName: !wellboreSettings?.lockName
          })}
          helpText="Select who is permitted to edit an existing wellbore name in the hierarchy."
        >
          {t(translations.settings_editWellboreName)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellboreSettings?.lockSidetrack}
          handleSelectChange={() => onChangeWellboreHierarchySettings({
            lockSidetrack: !wellboreSettings?.lockSidetrack
          })}
          helpText="Select who is permitted to edit the sidetrack definition of an existing wellbore in the hierarchy."
        >
          {t(translations.settings_editWellboreSidetrack)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellboreSettings?.identifierInputs?.isLocked}
          handleSelectChange={() => onChangeWellboreHierarchySettings({
            identifierInputs: { isLocked: !wellboreSettings?.identifierInputs?.isLocked}
          })}
          helpText="Select who is permitted to edit the optional well identifiers of an existing well in the hierarchy.\n This includes API numbers, NPD numbers, UWI numbers etc.\n These identifiers can be defined both on the well level and wellbore level."
        >
          {t(translations.settings_editWellboreIdentifiers)}
        </SettingField>
      )
    },
    {
      details: (
        <SettingField
          selectedOption={wellboreSettings?.lockNodeDeletion}
          handleSelectChange={() => onChangeWellboreHierarchySettings({
            lockNodeDeletion: !wellboreSettings?.lockNodeDeletion
          })}
          helpText="Select who is permitted to delete an existing wellbore - and all the data associated with a wellbore."
        >
          {t(translations.settings_deleteWellbore)}
        </SettingField>
      )
    },
  ];

  const listProperties: any = {
    name: heading,
    items: items?.filter(item => item),
    actions: [],
  };

  return (
    <List list={listProperties} drawer />
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchySettings: selectHierarchySettings(entities),
});

const Component = connect(mapStateToProps)(WellboreSettings);

export { Component as WellboreSettings };
