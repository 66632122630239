import { ability, ActionMap } from '@oliasoft/authorization-ui';
import { isObject } from 'lodash';
import { caslEnums } from '~src/enums/casl-enums';

export const toggleHierarchyElementSettings = (hierarchySettings: any, status = true): any => {
  return Object.keys(hierarchySettings).reduce((acc: any, key: string) => {
    if (!isObject(hierarchySettings[key])) {
      return { ...acc, [key]: status };
    }
    if (isObject(hierarchySettings[key])) {
      return { ...acc, [key]: toggleHierarchyElementSettings(hierarchySettings[key], status) };
    }
    return acc;
  }, {});
};

export const canManageHierarchySettings = () => ability.can(ActionMap.MANAGE, caslEnums.hierarchySettings)