import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Column, DisabledContext, Spacer, Heading, Accordion } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { selectCompanySettingsData, selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { updateHierarchySettings } from '~src/store/entities/company-settings/company-settings';
import { canManageHierarchySettings } from '~src/views/settings/company-settings/hierarchy/utils';
import { useParams } from 'react-router';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { HierarchySettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import { hierarchyEntities } from './hierarchy-entities';
import SettingHeading from '../setting-heading';
import type { TRootState } from '~src/store/store-types';

export type OneHierarchySetting = {
  [K in keyof HierarchySettingsShape]: Record<K, HierarchySettingsShape[K]>;
}[keyof HierarchySettingsShape];

export type THierarchySettingsItemProps = {
  heading: string, 
  hierarchySettings: HierarchySettingsShape, 
  onChangeHierarySettings: (arg: OneHierarchySetting) => void, 
  };


const HierarchyPermissions = ({
  hierarchySettings,
  companySettings,
  updateHierarchySettings
}: PropsFromRedux) => {
  const { t } = useTranslation();
  const { company = '' } = useParams();
  
  const onChangeHierarchySettings = (changedHierarchySettings: OneHierarchySetting) => {
    const changedKey = Object.keys(changedHierarchySettings)?.[0];
    const changes = {
      ...companySettings,
      hierarchySettings: {
        ...hierarchySettings,
        [changedKey]: changedHierarchySettings[changedKey as keyof OneHierarchySetting]
      },
    };
    updateHierarchySettings(changes, company);
  };
  return (
    <Column padding={true}>
      <SettingHeading heading={t(translations.settings_hierarchyPermissions)} />
      <Accordion expanded heading={<Heading>{t(translations.settings_hierarchyPermissions)}</Heading>} managed bordered>
        <DisabledContext.Provider value={!canManageHierarchySettings()}>
          <Row wrap spacing={0}>
            {hierarchyEntities?.map((props, idx) => (
              <Column key={idx} width="100%" widthTablet="50%" widthMobile="100%">
                <props.component heading={props.heading} onChangeHierarySettings={onChangeHierarchySettings} />
                <Spacer />
              </Column>
            ))}
          </Row>
        </DisabledContext.Provider>
      </Accordion>
    </Column>
  );
};

const mapDispatchToProps = {
  updateHierarchySettings
};

const mapStateToProps = ({ entities }: TRootState) => ({
  companySettings: selectCompanySettingsData(entities),
  hierarchySettings: selectHierarchySettings(entities),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Component = withErrorBoundary(connector(HierarchyPermissions));

type PropsFromRedux = ConnectedProps<typeof connector>;

export { Component as HierarchyPermissions };
