import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import { toast } from '@oliasoft-open-source/react-ui-library';
import { unum } from '@oliasoft-open-source/units';
import { linkFields, NO_TEMPLATE } from '~src/enums/settings';
import { isEmpty } from 'lodash';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const activityModel = createSlice({
  name: 'activityModel',
  initialState: {
    isAdding: false,
    isFetching: false,
    isDeleting: false,
    isReordering: false,
    isSchematicEmpty: false,
    isTemplatesEmpty: false,
    showImportOperationModal: false,
    isOperationsImportFetching: false,
    showCreateActivityModelModal: false,
    isCreateActivityModelFetching: false,
    isOperationsImportExcelFetching: false,
    importExcelSuccess: false,
    isFetchingPreviewTimes: false,
    operationsImportData: [],
    operations: [],
    codes: [],
    linkFields: [],
    sections: [],
    activeSection: null,
    sectionItems: [],
    template: NO_TEMPLATE,
    creationErrors: [],
    sectionTypes: [],
    offsetActivities: {},
    showOffsetPreviewModal: false,
  },
  reducers: {
    operationAddRequested: (activityModel) => {
      activityModel.isAdding = true;
    },
    /**
     * Upsert operation
     *
     * @param activityModel
     * @param action action.payload example:
     * {
        name: "Test",
        from: 2,
        to: 4,
        comments: "test comment",
        projectId: 1
      }
     */
    operationAdded: (activityModel, action) => {
      activityModel.isAdding = false;
      const existingIndex = activityModel.operations.findIndex(
        (item) => item.operationId === action.payload.operationId,
      );

      if (existingIndex === -1) {
        const lastIndex = activityModel.operations.reduce(
          (lastIndex, item, index) => {
            return item.projectSectionId === action.payload.projectSectionId
              ? index
              : lastIndex;
          },
          -1,
        );
        const insertIndex =
          lastIndex === -1 ? activityModel.operations.length : lastIndex + 1;
        action.payload.tasks = [];
        activityModel.operations.splice(insertIndex, 0, action.payload);
        activityModel.operations = activityModel.operations.map((item, i) => {
          item.active = i === insertIndex;
          return item;
        });
      } else {
        activityModel.operations[existingIndex] = {
          ...activityModel.operations[existingIndex],
          ...action.payload,
        };
        activityModel.operations[existingIndex].active = true;
      }
    },
    operationAddRequestFailed: (activityModel) => {
      activityModel.isAdding = false;
    },
    operationsRequested: (activityModel) => {
      activityModel.isFetching = true;
    },
    operationsFetched: (activityModel, action) => {
      activityModel.isFetching = false;
      activityModel.operations = action.payload.operations;
      activityModel.codes = action.payload.codes;
      activityModel.linkFields = action.payload.linkFields;
      activityModel.sections = action.payload.sections;
      activityModel.sectionTypes = action.payload.sectionTypes;
    },
    operationsFetchRequestFailed: (activityModel) => {
      activityModel.isFetching = false;
    },
    operationsDeselected: (activityModel) => {
      activityModel.operations = activityModel.operations.map((item) => {
        item.active = false;
        return item;
      });
    },
    operationRemoved: (activityModel, action) => {
      const { operations } = activityModel;
      const index = operations.findIndex(
        (operation) => operation.operationId === action.payload,
      );
      delete operations[index];
      activityModel.operations = operations.filter((i) => i);
    },
    operationsRemoved: (activityModel) => {
      activityModel.isGenerateFromSchematic = false;
      activityModel.operations = [];
      activityModel.sectionItems = [];
    },
    operationSelected: (activityModel, action) => {
      const { operations } = activityModel;
      const index = operations.findIndex(
        (operation) => operation.operationId === action.payload,
      );
      activityModel.operations = operations.map((item, i) => {
        item.active = i === index;
        return item;
      });

      activityModel.operations[index].tasks = activityModel.operations[
        index
      ].tasks.map((item) => {
        item.active = false;
        return item;
      });
    },
    operationsReorderRequested: (activityModel, action) => {
      activityModel.isReordering = true;
      activityModel.operations = action.payload;
    },
    operationsReordered: (activityModel) => {
      activityModel.isReordering = false;
    },
    operationsCleared: (activityModel) => {
      activityModel.operations = [];
      activityModel.activeSection = null;
    },
    taskAddRequested: (activityModel) => {
      activityModel.isAdding = true;
    },
    bulkBranchUpdateRequested: (activityModel) => {
      activityModel.isAdding = true;
    },
    bulkBranchUpdateRequestFailed: (activityModel) => {
      activityModel.isAdding = false;
    },

    /**
     * Upsert task
     *
     * @param activityModel
     * @param action action.payload example:
     * {
        taskId: 14,
        name: "test",
        certainty: 2,
        estimateType: 1,
        from: 1,
        to: 3,
        min: 2,
        most: 3,
        max: 5,
        active: true,
        operationId: 6,
        distribution: 'pert'
      }
     */
    taskAdded: (activityModel, action) => {
      activityModel.isAdding = false;
      const { operationId, taskId } = action.payload;
      const operationIndex = activityModel.operations.findIndex(
        (item) => item.operationId === operationId,
      );
      const tasks = activityModel.operations[operationIndex]?.tasks ?? [];
      const taskIndex = tasks.findIndex((item) => item.taskId === taskId);
      if (taskIndex === -1) {
        const index =
          activityModel.operations[operationIndex].tasks.push(action.payload) -
          1;
        if (!action.payload.isBranch) {
          activityModel.operations[operationIndex].tasks =
            activityModel.operations[operationIndex].tasks.map((item, i) => {
              item.active = i === index;
              return item;
            });
        }
      } else {
        activityModel.operations[operationIndex].tasks[taskIndex] =
          action.payload;
        activityModel.operations[operationIndex].tasks[taskIndex].active = true;
      }
    },
    taskAddRequestFailed: (activityModel) => {
      activityModel.isAdding = false;
    },

    bulkBranchUpdated: (activityModel) => {
      activityModel.isAdding = false;
    },

    taskRemoved: (activityModel, action) => {
      const { ids, task } = action.payload;
      const operationIndex = activityModel.operations.findIndex(
        (item) => item.active,
      );
      const { tasks } = activityModel.operations[operationIndex];
      for (const { taskId } of ids) {
        const tasksIndex = tasks.findIndex((item) => item.taskId === taskId);
        tasks.splice(tasksIndex, 1);
      }
      activityModel.operations[operationIndex].tasks = task.isBranch
        ? tasks.map((item) => {
            item.active = item.taskId === task.parentId;
            return item;
          })
        : tasks;
    },
    taskSelected: (activityModel, action) => {
      const operationIndex = activityModel.operations.findIndex(
        (item) => item.active,
      );
      const { tasks } = activityModel.operations[operationIndex];
      activityModel.operations[operationIndex].tasks = tasks.map((item, i) => {
        item.active = i === action.payload;
        return item;
      });
    },
    tasksDeselected: (activityModel) => {
      const operationIndex = activityModel.operations.findIndex(
        (item) => item.active,
      );
      activityModel.operations[operationIndex].tasks = activityModel.operations[
        operationIndex
      ].tasks.map((item) => {
        item.active = false;
        return item;
      });
    },
    tasksReorderRequested: (activityModel, action) => {
      const operationIndex = activityModel.operations.findIndex(
        (item) => item.active,
      );
      activityModel.isReordering = true;
      activityModel.operations[operationIndex].tasks = action.payload;
    },
    tasksReordered: (activityModel) => {
      activityModel.isReordering = false;
    },
    tasksReorderRequestFailed: (activityModel) => {
      activityModel.isReordering = false;
    },
    createActivityModelRequested: (activityModel) => {
      activityModel.isFetching = true;
    },
    createActivityModelFetched: (activityModel, action) => {
      activityModel.isFetching = false;
      activityModel.operations = action.payload.operations;
      activityModel.codes = action.payload.codes;
      activityModel.linkFields = action.payload.linkFields;
      activityModel.isSchematicEmpty = action.payload.isSchematicEmpty;
      activityModel.isTemplatesEmpty = action.payload.isTemplatesEmpty;
      activityModel.showCreateActivityModelModal = false;
      activityModel.sectionItems = action.payload.projectSections;
      activityModel.template = action.payload.template;
      activityModel.creationErrors = action.payload.errors;
    },
    createActivityModelFailed: (activityModel) => {
      activityModel.isFetching = false;
    },
    templateUpdated: (activityModel, action) => {
      activityModel.template = action.payload;
    },
    clearCreateModelFlags: (activityModel) => {
      activityModel.isSchematicEmpty = false;
      activityModel.isTemplatesEmpty = false;
    },
    showImportOperationModalUpdated: (activityModel, action) => {
      activityModel.showImportOperationModal = action.payload;
    },
    operationsImportDataRequested: (activityModel) => {
      activityModel.isOperationsImportFetching = true;
    },
    operationsImportDataFetched: (activityModel, action) => {
      activityModel.isOperationsImportFetching = false;
      activityModel.operationsImportData = action.payload.operationsImportData;
    },
    operationsImportDataRequestFailed: (activityModel) => {
      activityModel.isOperationsImportFetching = false;
    },
    operationsImported: (activityModel, action) => {
      activityModel.operations = action.payload.operations;
      activityModel.codes = action.payload.codes;
      activityModel.linkFields = action.payload.linkFields;
      activityModel.sectionItems = action.payload.projectSections;
      activityModel.isOperationsImportFetching = false;
      activityModel.showImportOperationModal = false;
    },
    showCreateActivityModelModalUpdated: (activityModel, action) => {
      activityModel.showCreateActivityModelModal = action.payload;
    },
    operationsImportExcelDataRequested: (activityModel) => {
      activityModel.isOperationsImportExcelFetching = true;
      activityModel.importExcelSuccess = false;
    },
    operationsImportExcelSuccess: (activityModel) => {
      activityModel.isOperationsImportExcelFetching = false;
      activityModel.importExcelSuccess = true;
    },
    operationsImportExcelFailed: (activityModel) => {
      activityModel.isOperationsImportExcelFetching = false;
      activityModel.importExcelSuccess = false;
    },
    riskValueUpdated: (activityModel, action) => {
      const operationIndex = activityModel.operations.findIndex(
        (operation) => operation.operationId === action.payload.operationId,
      );
      if (operationIndex !== -1) {
        const taskIndex = activityModel.operations[
          operationIndex
        ].tasks.findIndex((task) => task.taskId === action.payload.taskId);
        if (taskIndex !== -1) {
          activityModel.operations[operationIndex].tasks[taskIndex][
            action.payload.field
          ] = action.payload.value;
        }
      }
    },
    updateIsGenerateFromSchematicRequested: (activityModel) => {
      activityModel.isFetching = true;
    },
    updateIsGenerateFromSchematicRecieved: (activityModel, action) => {
      activityModel.isGenerateFromSchematic =
        action.payload.isGenerateFromSchematic;
      activityModel.isFetching = false;
    },
    operationsRemovedBySection: (activityModel, action) => {
      const { operationIds } = action.payload;
      const { operations } = activityModel;
      const updatedOperations = operations.filter(
        (operation) => !operationIds.includes(operation.operationId),
      );
      activityModel.operations = updatedOperations;
    },
    getTimeFromOffsetWellsRequested: (activityModel) => {
      activityModel.isFetching = true;
    },
    getTimeFromOffsetWellsRecieved: (activityModel, action) => {
      const operationIndex = activityModel.operations.findIndex(
        (item) => item.operationId === action.payload.operation.operationId,
      );
      const taskIndex = activityModel.operations[
        operationIndex
      ].tasks.findIndex((item) => item.taskId === action.payload.task.taskId);
      activityModel.operations[operationIndex].tasks[
        taskIndex
      ].offsetWellsTimeEstimate = action.payload.offsetWellsTimeEstimate;
      activityModel.operations[operationIndex].tasks[
        taskIndex
      ].isApplyFromOffset = true;
      activityModel.isFetching = false;
    },
    getTimeFromOffsetWellsFailed: (activityModel) => {
      activityModel.isFetching = false;
    },
    sectionSelected: (activityModel, action) => {
      const { operations } = activityModel;
      activityModel.activeSection = action.payload;
      activityModel.operations = operations.map((item) => {
        item.active = false;
        return item;
      });
    },
    sectionAddRequested: (activityModel) => {
      activityModel.isAdding = true;
    },
    sectionAdded: (activityModel, action) => {
      activityModel.isAdding = false;
      const sectionIndex = activityModel.sectionItems.findIndex(
        (section) =>
          section.projectSectionId === action.payload.projectSectionId,
      );

      activityModel.operations = activityModel.operations.map((item) => {
        item.active = false;
        return item;
      });

      if (sectionIndex === -1) {
        activityModel.sectionItems.push(action.payload);
        activityModel.activeSection = action.payload;
      } else {
        activityModel.sectionItems[sectionIndex] = action.payload;
        activityModel.activeSection = action.payload;
      }
    },
    sectionsRequested: (activityModel) => {
      activityModel.isFetching = true;
    },
    sectionsRecieved: (activityModel, action) => {
      activityModel.isFetching = false;
      activityModel.sectionItems = action.payload;
    },
    sectionsRequestFailed: (activityModel) => {
      activityModel.isFetching = false;
    },
    sectionRemoved: (activityModel, action) => {
      const { sectionItems, operations } = activityModel;
      const index = sectionItems.findIndex(
        (section) =>
          section.projectSectionId === action.payload.projectSectionId,
      );
      delete sectionItems[index];
      activityModel.operations = operations.filter(
        (operation) =>
          operation.projectSectionId !== action.payload.projectSectionId,
      );
      activityModel.sectionItems = sectionItems.filter((i) => i);
      activityModel.activeSection = null;
      activityModel.isDeleting = false;
    },
    sectionsRemoved: (activityModel) => {
      activityModel.sectionItems = [];
      activityModel.operations = [];
      activityModel.activeSection = null;
      activityModel.isDeleting = false;
    },
    sectionRemoveRequested: (activityModel) => {
      activityModel.isDeleting = true;
    },
    sectionRemoveFailed: (activityModel) => {
      activityModel.isDeleting = false;
    },
    sectionsDeselected: (activityModel) => {
      activityModel.sectionItems = activityModel.sectionItems.map((item) => {
        item.active = false;
        return item;
      });
    },
    sectionsReorderRequested: (activityModel, action) => {
      activityModel.isFetching = true;
      activityModel.sectionItems = action.payload;
    },
    sectionsReordered: (activityModel) => {
      activityModel.isFetching = false;
    },
    sectionsAddFailed: (activityModel) => {
      activityModel.isAdding = false;
    },
    activitiesCodesNullified: (activityModel, action) => {
      const { operations } = activityModel;
      const operationIndex = operations.findIndex(
        (item) => item.operationId === action.payload,
      );

      if (operationIndex !== -1) {
        activityModel.operations[operationIndex].tasks =
          nullifyOperationActivityId(operations[operationIndex].tasks);
      }
    },
    operationsCodesNullified: (activityModel, action) => {
      const { operations } = activityModel;
      operations.forEach((operation, index) => {
        if (operation.projectSectionId === action.payload) {
          activityModel.operations[index].sectionsOperationId = null;
          activityModel.operations[index].tasks = nullifyOperationActivityId(
            operations[index].tasks,
          );
        }
      });
    },
    getPreviewTimesFromOffsetWellsRequested: (activityModel) => {
      activityModel.isFetchingPreviewTimes = true;
    },
    getPreviewTimesFromOffsetWellsRecieved: (activityModel, action) => {
      activityModel.isFetchingPreviewTimes = false;
      activityModel.offsetActivities = action.payload;
      activityModel.showOffsetPreviewModal = true;
    },
    updateShowOffsetPreviewModal: (activityModel) => {
      activityModel.showOffsetPreviewModal = false;
      activityModel.offsetActivities = {};
    },
  },
});

export const initialOperation = {
  name: '',
  from: 0,
  to: 0,
  comments: '',
  tasks: [],
  operationCode: null,
  subOperationCode: null,
  depthFromLink: linkFields.CUSTOM,
  depthToLink: linkFields.CUSTOM,
  sectionsOperationId: null,
};

export const initialTask = {
  name: '',
  certainty: 100,
  estimateType: estimateTypes.OPERATION_TIME,
  from: 1,
  to: 0,
  depthFromLink: linkFields.CUSTOM,
  depthToLink: linkFields.CUSTOM,
  min: 1,
  most: 2,
  max: 3,
  activityCode: null,
  comments: '',
  distribution: distributionTypes.PERT,
  withBranch: false,
  isBranch: false,
  isApplyFromOffset: false,
  sectionsOperationActivityId: null,
  offsetWellsTimeEstimate: null,
};

export const {
  operationAddRequested,
  operationAdded,
  operationAddRequestFailed,
  operationsRequested,
  operationsFetched,
  operationsFetchRequestFailed,
  operationRemoved,
  operationSelected,
  operationsReordered,
  operationsReorderRequested,
  tasksReorderRequestFailed,
  operationsDeselected,
  taskAddRequested,
  taskAdded,
  taskAddRequestFailed,
  taskRemoved,
  taskSelected,
  tasksDeselected,
  tasksReorderRequested,
  tasksReordered,
  operationsCleared,
  createActivityModelRequested,
  createActivityModelFetched,
  createActivityModelFailed,
  clearCreateModelFlags,
  showImportOperationModalUpdated,
  operationsImportDataRequested,
  operationsImportDataRequestFailed,
  operationsImportDataFetched,
  operationsImported,
  showCreateActivityModelModalUpdated,
  operationsRemoved,
  operationsImportExcelDataRequested,
  operationsImportExcelSuccess,
  operationsImportExcelFailed,
  riskValueUpdated,
  updateIsGenerateFromSchematicRequested,
  updateIsGenerateFromSchematicRecieved,
  operationsRemovedBySection,
  getTimeFromOffsetWellsRequested,
  getTimeFromOffsetWellsRecieved,
  getTimeFromOffsetWellsFailed,
  bulkBranchUpdateRequested,
  bulkBranchUpdateRequestFailed,
  bulkBranchUpdated,
  sectionSelected,
  sectionsRequested,
  sectionsRecieved,
  sectionsRequestFailed,
  sectionRemoved,
  sectionAddRequested,
  sectionAdded,
  sectionsAddFailed,
  sectionRemoveRequested,
  sectionRemoveFailed,
  sectionsDeselected,
  templateUpdated,
  sectionsReorderRequested,
  sectionsReordered,
  sectionsRemoved,
  activitiesCodesNullified,
  operationsCodesNullified,
  getPreviewTimesFromOffsetWellsRequested,
  getPreviewTimesFromOffsetWellsRecieved,
  updateShowOffsetPreviewModal,
} = activityModel.actions;
export default activityModel.reducer;

/**
 * Add operation
 *
 * @param data
 * @return {*}
 */
export const addOperation = (data) =>
  apiCallBegan({
    url: '/api/operations',
    method: 'POST',
    data,
    onStart: operationAddRequested.type,
    onSuccess: (response) => ({
      type: operationAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToAddOperations),
        },
      });
      return {
        type: operationAddRequestFailed.type,
      };
    },
  });

/**
 * Get operations list
 */
export const getOperations = (projectId) =>
  apiCallBegan({
    url: `/api/operations?projectId=${projectId}`,
    method: 'GET',
    onStart: operationsRequested.type,
    onSuccess: (response) => ({
      type: operationsFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToGetOperations),
        },
      });
      return {
        type: operationsFetchRequestFailed.type,
      };
    },
  });

/**
 * Remove operation by id
 *
 * @param id
 * @param data
 */
export const removeOperation = (id, data = {}) =>
  apiCallBegan({
    url: `/api/operation/${id}`,
    method: 'DELETE',
    data,
    onSuccess: () => {
      return {
        type: operationRemoved.type,
        payload: id,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToDeleteOperation),
        },
      });
    },
  });

/**
 * Remove all operations
 *
 * @param projectId
 */
export const removeAllOperations = (projectId) =>
  apiCallBegan({
    url: `/api/operations/${projectId}`,
    method: 'DELETE',
    onSuccess: () => {
      return {
        type: operationsRemoved.type,
        payload: projectId,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToDeleteOperations),
        },
      });
    },
  });

/**
 * Change order of operations
 *
 * @param {Object} reorder
 * @param {Array} allOperationsList
 * @param {Array} sectionItems
 * @param {Array} activesSectionOperationList
 * @param {String} activeSessionId
 * @return {*}
 */
export const reorderOperations = (
  reorder = null,
  allOperationsList,
  sectionItems,
  activesSectionOperationList,
  activeSessionId,
) => {
  const orderedOperations = [];
  if (reorder) {
    const operations = activesSectionOperationList.slice();
    const { from, to } = reorder;
    operations.splice(to, 0, operations.splice(from, 1)[0]);
    sectionItems.forEach((sectionItem) => {
      allOperationsList.forEach((operation) => {
        if (
          operation.projectSectionId === sectionItem.projectSectionId &&
          operation.projectSectionId !== activeSessionId
        ) {
          orderedOperations.push(operation);
        }
      });
      if (sectionItem.projectSectionId === activeSessionId) {
        orderedOperations.push(...operations);
      }
    });
  } else {
    sectionItems.forEach((sectionItem) => {
      allOperationsList.forEach((operation) => {
        if (operation.projectSectionId === sectionItem.projectSectionId) {
          orderedOperations.push(operation);
        }
      });
    });
  }
  const data = orderedOperations.map((operation, i) => ({
    operationId: operation.operationId,
    position: i,
  }));
  return apiCallBegan({
    url: '/api/operations/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: operationsReorderRequested.type,
      payload: orderedOperations,
    }),
    onSuccess: operationsReordered.type,
  });
};

export const addInitialOperation = (
  projectId,
  projectSectionId = null,
  operationHeaderPosition = null,
  name = 'New Operation',
) =>
  addOperation({
    ...initialOperation,
    name,
    projectSectionId,
    operationHeaderPosition,
    projectId,
  });

/**
 * Add task
 *
 * @param data
 */
export const addTask = (data) =>
  apiCallBegan({
    url: '/api/tasks',
    method: 'POST',
    data: { ...data, parentId: data.parentId === 0 ? null : data.parentId },
    onStart: taskAddRequested.type,
    onSuccess: (response) => {
      return {
        type: taskAdded.type,
        payload: response,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToAddTask),
        },
      });
    },
  });

/**
 * Remove task
 *
 * @param task
 * @param data
 */
export const removeTask = (task, data = {}) =>
  apiCallBegan({
    url: `/api/tasks/${task.taskId}`,
    method: 'DELETE',
    data,
    onSuccess: (response) => {
      return {
        type: taskRemoved.type,
        payload: { ids: response, task },
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToDeleteActivity),
        },
      });
    },
  });

/**
 *  Reorder tasks
 *
 * @param orderedTasks
 * @param list
 * @return {*}
 */
export const reorderTasks = (orderedTasks, list) => {
  const data = orderedTasks.map((task, i) => ({
    taskId: task.id,
    parentId: task.parent,
    position: i,
  }));
  const tasks = list.map((item) => {
    const reorderedTask = data.find((task) => task.taskId === item.taskId);
    if (reorderedTask) {
      return {
        ...item,
        parentId: reorderedTask.parentId,
        position: reorderedTask.position,
      };
    }
    return item;
  });
  tasks.sort((a, b) => a.position - b.position);

  return apiCallBegan({
    url: '/api/tasks/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: tasksReorderRequested.type,
      payload: tasks,
    }),
    onSuccess: tasksReordered.type,
    onError: tasksReorderRequestFailed.type,
  });
};

export const addInitialTask = (
  operationId,
  projectId,
  name,
  parentId = null,
  linkedFields,
) => {
  let toAndFrom = {};
  const sectionTDOption = linkedFields.find(
    (option) => option.label === 'Section TD',
  );
  const wellheadDepthOption = linkedFields.find(
    (option) => option.label === 'Wellhead Depth',
  );
  const rigFloorOption = linkedFields.find(
    (option) => option.label === 'Rig Floor',
  );

  if (sectionTDOption !== undefined) {
    toAndFrom = {
      to: unum(sectionTDOption.value, 'm'),
      depthToLink: sectionTDOption.valueKey,
      from: unum(wellheadDepthOption?.value, 'm'),
      depthFromLink: wellheadDepthOption.valueKey,
    };
  } else {
    toAndFrom = {
      to: unum(wellheadDepthOption?.value, 'm'),
      depthToLink: wellheadDepthOption.valueKey,
      from: unum(rigFloorOption?.value, 'm'),
      depthFromLink: rigFloorOption.valueKey,
    };
  }
  return addTask({
    ...initialTask,
    name,
    operationId,
    parentId,
    projectId,
    ...toAndFrom,
  });
};

/**
 * Add an initial branch to a risk.
 *
 * @param {string} operationId - The operation ID of the task.
 * @param {string} projectId - The project ID of the task.
 * @param {string} name - The name of the task.
 * @param {?string} parentId - The ID of the parent task (optional).
 */
export const addInitialBranch = (
  operationId,
  projectId,
  name,
  parentId = null,
) =>
  addTask({
    ...initialTask,
    name,
    operationId,
    parentId,
    projectId,
    isBranch: true,
    certainty: 0,
  });

export const getCodesList = (type, data, parentId = null) => {
  let result = data.filter((item) => item.type === type);
  if (parentId) {
    result = result.filter((item) => item.parentId === parentId);
  }
  return result.map((item) => ({
    label: item.code,
    value: item.codeId,
    details: item.description,
  }));
};

/**
 * Create activity model based on well details and template
 *
 * @param projectId
 * @param templateId
 * @param includeCostSetup
 */
export const createActivityModel = (projectId, templateId, includeCostSetup) =>
  apiCallBegan({
    url: `/api/well-details/activity-model/${projectId}/${templateId}?cost=${includeCostSetup}`,
    method: 'GET',
    onStart: createActivityModelRequested.type,
    onSuccess: (response) => ({
      type: createActivityModelFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.activityModel_failedToCreateActivityModel,
          ),
        },
      });
      return {
        type: createActivityModelFailed.type,
      };
    },
  });

export const getOperationsImportData = () =>
  apiCallBegan({
    url: '/api/operations/import',
    method: 'GET',
    onStart: operationsImportDataRequested.type,
    onSuccess: (response) => ({
      type: operationsImportDataFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToGetImportData),
        },
      });
      return {
        type: operationsImportDataRequestFailed.type,
      };
    },
  });

export const importOperations = (data) =>
  apiCallBegan({
    url: '/api/operations/import',
    method: 'POST',
    data,
    onStart: operationsImportDataRequested.type,
    onSuccess: (response) => {
      return {
        type: operationsImported.type,
        payload: response,
      };
    },
    onError: (error) => {
      toast({
        message: {
          type: 'Error',
          heading: i18n.t(translations.activityModel_failedToImportOperations),
          content: Array.isArray(error?.errorMessages)
            ? error.errorMessages.join('; ')
            : error?.errorMessages || error.message,
        },
      });
    },
  });

export const importOperationsFromExcel = (data, projectId) =>
  apiCallBegan({
    url: `/api/operations/import-excel/${projectId}`,
    method: 'POST',
    data,
    onStart: operationsImportExcelDataRequested.type,
    onSuccess: (response) => ({
      type: operationsImportExcelSuccess.type,
      payload: response,
    }),
    onError: (error) => {
      toast({
        message: {
          type: 'Error',
          heading: i18n.t(translations.activityModel_failedToImportFromExcel),
          content: Array.isArray(error?.errorMessages)
            ? error.errorMessages.join('; \n')
            : error?.errorMessages || error.message,
        },
      });
      return {
        type: operationsImportExcelFailed.type,
      };
    },
  });

export const downloadExampleExcel = () =>
  apiCallBegan({
    url: `/api/operations/download-excel`,
    responseType: 'blob',
    method: 'GET',
    onSuccess: (response) => {
      const dataString = window.URL.createObjectURL(new Blob([response]));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataString);
      downloadAnchorNode.setAttribute(
        'download',
        `activity-model-example.xlsx`,
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  });

export const updateIsGenerateFromSchematic = (data) =>
  apiCallBegan({
    url: `/api/well-details/is-generate-from-schematic`,
    method: 'POST',
    data,
    onStart: updateIsGenerateFromSchematicRequested.type,
    onSuccess: updateIsGenerateFromSchematicRecieved.type,
  });

export const getTimeFromOffsetWells = (projectId, data, operation) => {
  return apiCallBegan({
    url: `/api/tasks/get-time-from-offset-wells/${projectId}`,
    method: 'POST',
    data,
    onStart: getTimeFromOffsetWellsRequested.type,
    onSuccess: (response) => {
      if (isEmpty(response)) {
        toast({
          message: {
            type: 'Info',
            content: i18n.t(translations.activityModel_noResultsFound),
          },
        });
      }
      return {
        type: getTimeFromOffsetWellsRecieved.type,
        payload: { offsetWellsTimeEstimate: response, task: data, operation },
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.activityModel_failedToGetTimeFromOffsetWells,
          ),
        },
      });
      return {
        type: getTimeFromOffsetWellsFailed.type,
      };
    },
  });
};

export const bulkBranchUpdate = (data) =>
  apiCallBegan({
    url: '/api/tasks/bulk-update',
    method: 'POST',
    data,
    onStart: bulkBranchUpdateRequested.type,
    onSuccess: (response) => ({
      type: bulkBranchUpdated.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToUpdateBranches),
        },
      });
      return {
        type: bulkBranchUpdateRequestFailed.type,
      };
    },
  });

export const addSection = (projectId, data) => {
  return apiCallBegan({
    url: `/api/section/${projectId}`,
    method: 'POST',
    data,
    onStart: sectionAddRequested.type,
    onSuccess: (response) => {
      return {
        type: sectionAdded.type,
        payload: response,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToAddSection),
        },
      });
      return {
        type: sectionsAddFailed.type,
      };
    },
  });
};

/**
 * Get sections
 *
 * @param {string} projectId
 */
export const getSections = (projectId) => {
  return apiCallBegan({
    url: `/api/section/${projectId}`,
    method: 'GET',
    onStart: sectionsRequested.type,
    onSuccess: sectionsRecieved.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToGetSections),
        },
      });
      return {
        type: sectionsRequestFailed.type,
      };
    },
  });
};

/**
 * Remove Section
 *
 * @param {string} projectSectionId
 */
export const removeSection = (projectSectionId, data = {}) =>
  apiCallBegan({
    url: `/api/section/${projectSectionId}`,
    method: 'DELETE',
    data,
    onStart: sectionRemoveRequested.type,
    onSuccess: (response) => {
      return {
        type: sectionRemoved.type,
        payload: response,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToDeleteSection),
        },
      });
      return {
        type: sectionRemoveFailed.type,
      };
    },
  });

/**
 * Removes all sections for a given project ID.
 *
 * @param {number} projectId - The ID of the project.
 */
export const removeAllSections = (projectId) =>
  apiCallBegan({
    url: `/api/sections/${projectId}`,
    method: 'DELETE',
    onStart: sectionRemoveRequested.type,
    onSuccess: () => {
      return {
        type: sectionsRemoved.type,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.activityModel_failedToDeleteSections),
        },
      });
      return {
        type: sectionRemoveFailed.type,
      };
    },
  });

/**
 * Reorders sections in a list and makes an API call to update the order on the server.
 *
 * @param {Object} reorder - An reorder object.
 * @param {Array} list - The list of sections to reorder.
 */
export const reorderSections = (reorder, list) => {
  const sections = list.map((item) => item);
  const { to, from } = reorder;
  sections.splice(to, 0, sections.splice(from, 1)[0]);
  const data = sections.map((section, i) => ({
    projectSectionId: section.projectSectionId,
    position: i,
  }));

  return apiCallBegan({
    url: '/api/section/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: sectionsReorderRequested.type,
      payload: sections,
    }),
    onSuccess: sectionsReordered.type,
  });
};

/**
 * Get a list of all activities in offsetWells with matching sectionOperationActivityId.
 * @param {string} projectId - ProjectID of estimate.
 * @param {string} activityId - ID of activity type.
 * @param {boolean} isDrill - Is drill operation.
 */
export const getPreviewTimesFromOffsetWells = (
  projectId,
  activityId,
  isDrill,
) => {
  return apiCallBegan({
    url: `/api/tasks/get-preview-times-from-offset-wells/${projectId}/${activityId}?isDrill=${isDrill}`,
    method: 'GET',
    onStart: getPreviewTimesFromOffsetWellsRequested.type,
    onSuccess: getPreviewTimesFromOffsetWellsRecieved.type,
    onError: getTimeFromOffsetWellsFailed.type,
  });
};

const nullifyOperationActivityId = (tasks) =>
  tasks.map((task) => {
    task.sectionsOperationActivityId = null;
    return task;
  });
