import { WellConfiguration } from "~src/common/interfaces/hierarchy-settings.interface"
import { HierarchyLevelType } from "~src/enums/hierarchy"
import i18n from "~src/internationalisation/i18n"
import translations from '~src/internationalisation/translation-map.json';

export const getHierarchyLevelLable = (hierarchyLevel: string, hierarchyLabelType: string, plural = false) => {
    if (hierarchyLevel === HierarchyLevelType.Field) {
        if (hierarchyLabelType === WellConfiguration.ONSHORE) {
            return plural ? i18n.t(translations.regions) : i18n.t(translations.region)
        };
        return plural ? i18n.t(translations.fields) : i18n.t(translations.field)
    };
    if (hierarchyLevel === HierarchyLevelType.Site) {
        if (hierarchyLabelType === WellConfiguration.ONSHORE) {
            return plural ? i18n.t(translations.pads) : i18n.t(translations.pad)
        };
        return plural ? i18n.t(translations.sites) : i18n.t(translations.site)
    };
    return '';
};