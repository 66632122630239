import { MutableRefObject } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getSimulationDataForWaterfall } from "~src/views/simulations/result-overview/result-utils";
import { formatToMillions } from '~common/format-data/format-to-millions';
import autoTable from "jspdf-autotable";
import { IProjectsOverview } from "~src/common/interfaces/project";
import { lineInterval } from "./common";
import { addPageTitle } from "./add-page-title";
import { addHeaderFooter } from "./add-header-footer";

export const addSectionWaterfallCostPage = async (
    doc: jsPDF,
    padding: number,
    forecastLogoHeight: number,
    chartRef: MutableRefObject<null>,
    simulationsResult: any,
    projectOverview: IProjectsOverview
  ) => {
    doc.addPage();
    addHeaderFooter(doc, projectOverview);
    addPageTitle(
      doc,
      'Section Waterfall (Cost)',
      padding,
      forecastLogoHeight + padding + lineInterval,
    );
    if (!chartRef.current) {
      console.error('The component is not mounted yet.');
      return;
    }
    const element = chartRef.current;
    const canvas = await html2canvas(element, { scale: 1 });
    // Convert canvas to image
    const imgData = canvas.toDataURL('image/png');
    const pdfWidth = doc.internal.pageSize.getWidth();
    const chartWidth = pdfWidth - 2 * padding;
    const chartHeight = (canvas.height * chartWidth) / canvas.width;

    doc.addImage(imgData, 'PNG', padding, 35, chartWidth, chartHeight);

    const { sections, values, totalSum } = getSimulationDataForWaterfall(
      simulationsResult,
      1,
    );
    const rows = sections?.labels?.map((section: string, idx: number) => [
      { content: section, styles: { fontStyle: 'bold' } },
      formatToMillions(values[idx]),
    ]) || [];
    const tableStartY = padding + forecastLogoHeight + chartHeight + 2 * lineInterval;
    autoTable(doc, {
      head: [['Section', 'Cost [MUSD]']],
      body: [
        ...rows,
        [
          { content: 'Total', styles: { fontStyle: 'bold' } },
          formatToMillions(totalSum),
        ],
      ],
      startY: tableStartY,
      theme: 'plain',
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 'auto' },
      },
      styles: {
        fontSize: 10,
        cellPadding: { top: 2, right: 2, bottom: 2, left: 0 },
        halign: 'left',
      },

      didDrawCell: (data) => {
        const { cell, doc } = data;
        const { x, y, width, height } = cell;
        // Draw bottom border for each cell
        doc.setDrawColor('#90908d');
        doc.setLineWidth(0.2);
        doc.line(x, y + height, x + width, y + height);
      },
      margin: { top: 30 },
      didDrawPage: (data) => {
        if (data.pageNumber > 1) {
          addHeaderFooter(doc, projectOverview);
        }
      },
    });
  };