import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { List } from '@oliasoft-open-source/react-ui-library';
import {
  getItems,
  itemSelected,
  listCleaned,
} from '~store/entities/hierarchy/hierarchy';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { canManageHierarchySettings } from '~src/views/settings/company-settings/hierarchy/utils';
import {
  IField,
  ICountry,
  ISelectedProject,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { useListSorting } from '~common/sorting/use-list-sorting.tsx';
import { FetchingList } from '~common/lists/fetching-list.tsx';
import { isEmpty } from 'lodash';
import { HierarchyFieldSettingsShape } from '~src/common/interfaces/hierarchy-settings.interface';
import { selectHierarchyLabelsSettings } from '~src/store/entities/company-settings/selectors';
import { updateProjectHierarchy } from '../hierarchy-utils/local-storage-util';
import { getHierarchyLevelLable } from '../hierarchy-utils/utils'
import type { TRootState } from '~src/store/store-types';

type FieldsListProps = PropsFromRedux & {
  companyId: string;
  fields: IField[];
  activeCountry: ICountry | null;
  isFieldFetching: boolean;
  addButtonDisabled: boolean;
  createItem: () => void;
  onClickDelete: (id: string, type: HierarchyLevelType) => void;
  editItem: (item: IField, type: HierarchyLevelType) => void;
  selectedProject: ISelectedProject;
  settings: HierarchyFieldSettingsShape;
};

const FieldsList = ({
  companyId,
  getItems,
  fields,
  onClickDelete,
  itemSelected,
  addButtonDisabled,
  activeCountry,
  isFieldFetching,
  listCleaned,
  createItem,
  editItem,
  selectedProject,
  settings,
  hierarchyLabelType
}: FieldsListProps) => {
  useEffect(() => {
    if (activeCountry?.countryid) {
      const fetchData = async () => {
        await getItems(
          companyId,
          HierarchyLevelType.Field,
          activeCountry.countryid,
        );
        if (!isEmpty(selectedProject.fieldId)) {
          itemSelected({
            id: selectedProject.fieldId,
            type: HierarchyLevelType.Field,
          });
        }
      };
      fetchData();
    }

    return () => {
      listCleaned(HierarchyLevelType.Field);
    };
  }, [companyId, activeCountry]);
  const { items, sortAction } = useListSorting(fields);

  if (isFieldFetching) {
    return <FetchingList testId="field-list" name={getHierarchyLevelLable(HierarchyLevelType.Field, hierarchyLabelType)} />;
  }

  return (
    <List
      testId="field-list"
      list={{
        name: getHierarchyLevelLable(HierarchyLevelType.Field, hierarchyLabelType),
        actions: [
          sortAction,
          {
            label: 'Add',
            icon: 'add',
            onClick: () => createItem(),
            disabled: addButtonDisabled || isFieldFetching || (settings?.lockNodeCreation && !canManageHierarchySettings()),
          },
        ],
        items: items.map((field) => {
          const { childrenCount } = field.metadata;

          return {
            id: field.fieldid,
            name: field.name,
            metadata: `${childrenCount} ${getHierarchyLevelLable(
              HierarchyLevelType.Site, 
              hierarchyLabelType, 
              +childrenCount !== 1
            )}`,
            active: field.active,
            onClick: () => {
              itemSelected({
                id: field.fieldid,
                type: HierarchyLevelType.Field,
              });
              updateProjectHierarchy('fieldId', field.fieldid, selectedProject);
            },
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => editItem(field, HierarchyLevelType.Field),
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(field.fieldid, HierarchyLevelType.Field),
                disabled: settings?.lockNodeDeletion && !canManageHierarchySettings(),

              },
            ],
          };
        }),
      }}
      stickyHeader
      expanding
      scrollDetails={{
        scrollable: true,
        hideScrollbar: true,
      }}
    />
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  hierarchyLabelType: selectHierarchyLabelsSettings(entities)
})

const mapDispatchToProps = { getItems, itemSelected, listCleaned };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(FieldsList));

export { Container as FieldsList };
