import { useTranslation } from 'react-i18next';
import {
  Column,
  DisabledContext,
  Heading,
  Accordion,
  SettingField,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { HierarchyLabelSettings } from './hierarchy-label-settings';
import SettingHeading from './setting-heading';
import { canManageHierarchySettings } from './utils';

const Hierarchy = () => {
  const { t } = useTranslation();

  return (
    <Column padding={true}>
      <SettingHeading heading="Hierarchy" hasPermission={false} />
      <Accordion
        expanded
        heading={<Heading>{t(translations.settings_hierarchyType)}</Heading>}
        managed
        bordered
      >
        <DisabledContext.Provider value={!canManageHierarchySettings()}>
          <SettingField
            noPermission
            helpText={
              'Offshore means the hierarchy levels will be named Country - Field - Site - Well - Wellbore. \nOnshore means the hierarchy levels will be named Country - Region - Pad - Well - Wellbore. \nThis setting is non-destructive and can be changed without altering any data, it is just a naming convention.'
            }
          >
            <HierarchyLabelSettings />
          </SettingField>
        </DisabledContext.Provider>
      </Accordion>
    </Column>
  );
};


const Container = withErrorBoundary(Hierarchy);

export { Container as Hierarchy };


