import translations from '~src/internationalisation/translation-map.json';
import i18n from 'i18next';
import { CountrySettings } from './hierarchy-settings-items/country-settings';
import { FieldSettings } from './hierarchy-settings-items/field-settings';
import { SiteSettings } from './hierarchy-settings-items/site-settings';
import { WellSettings } from './hierarchy-settings-items/well-settings';
import { WellboreSettings } from './hierarchy-settings-items/wellbore-settings';



export const hierarchyEntities = [
  {
    heading: i18n.t(translations.settings_countryPermissions),
    component: CountrySettings,
  },
  {
    heading: i18n.t(translations.settings_fieldPermissions),
    component: FieldSettings,
  },
  {
    heading: i18n.t(translations.settings_sitePermissions),
    component: SiteSettings,
  },
  {
    heading: i18n.t(translations.settings_wellPermissions),
    component: WellSettings,
  },
  {
    heading: i18n.t(translations.settings_wellborePermissions),
    component: WellboreSettings,
  }
];
