import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import WellboreContentSettings from './wellbore-content-settings';
import WellContentSettings from './well-content-settings';

export const hierarchyContentEntities = [
  {
    heading: i18n.t(translations.settings_wellInputSettings),
    component: WellContentSettings,
  },
  {
    heading: i18n.t(translations.settings_wellboreInputSettings),
    component: WellboreContentSettings,
  },
];
