import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Select } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { wellConfiguration } from '~src/enums/settings';
import { connect, ConnectedProps } from 'react-redux';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { selectCompanySettingsData, selectHierarchySettings } from '~src/store/entities/company-settings/selectors';
import { updateHierarchySettings } from '~src/store/entities/company-settings/company-settings';
import { useParams } from 'react-router';
import type { TRootState } from '~src/store/store-types';

const HierarchyLabelSettings = ({
  companySettings,
  hierarchySettings,
  updateHierarchySettings
}: PropsFromRedux) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const [labelsSettings, setLabelsSettings] = useState(hierarchySettings?.labelsSettings);

  useEffect(() => setLabelsSettings(hierarchySettings?.labelsSettings), [hierarchySettings?.labelsSettings])

  const onChangeHierarchyLabels = (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>) => {
    const labelChanges = {
      ...labelsSettings,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    setLabelsSettings(labelChanges);
    updateHierarchySettings({
      ...companySettings,
      hierarchySettings: {
        ...hierarchySettings,
        labelsSettings: labelChanges
      }
    }, company!);
  };

  return (
    <Field label={t(translations.settings_hierarchyType)}>
      <Select
        name="type"
        options={[
          {
            label: t(translations.settings_offshore),
            value: wellConfiguration.OFFSHORE,
          },
          {
            label: t(translations.settings_onshore),
            value: wellConfiguration.ONSHORE,
          },
        ]}
        onChange={(e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement, MouseEvent>) => { onChangeHierarchyLabels(e); }}
        value={labelsSettings?.type}
        width="250px"
      />
    </Field>
  );
};

const mapDispatchToProps = {
  updateHierarchySettings
};

const mapStateToProps = ({ entities }: TRootState) => ({
  companySettings: selectCompanySettingsData(entities),
  hierarchySettings: selectHierarchySettings(entities),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(HierarchyLabelSettings));

export { Container as HierarchyLabelSettings };