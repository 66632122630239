import translations from '~src/internationalisation/translation-map.json';

export const companySettings = [
  { id: 'currency', name: translations.currency },
  { id: 'sectionsDataStructure', name: translations.dataStructure },
  { id: 'hierarchy', name: 'Hierarchy' },
  {
    id: 'hierarchyPermissions',
    name: 'Hierarchy permissions',
    level: 1,
    parent: 'hierarchy',
  },
  {
    id: 'hierarchyOptionalParameters',
    name: 'Hierarchy optional parameters',
    level: 1,
    parent: 'hierarchy',
  },
  { id: 'templates', name: translations.automationRulesets },
  { id: 'kpi', name: translations.kpis },
  { id: 'timeTracker', name: translations.timeTracker_timeTracker },
  { id: 'priceBook', name: translations.priceBook },
  { id: 'rigOperationTemplates', name: translations.rigOperationTemplates },
];
